export const HeadingsAnchor = `
  a {
    margin-top: 0.5rem;
    
    svg {
      transform: translateY(-100%);
      fill: var(--theme-ui-colors-grey,#73737d);
    }
  }
`;
