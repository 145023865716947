import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

import { Icon } from '@narative/gatsby-theme-novela/src/types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg
        width="192"
        viewBox="0 0 1200.000000 200.000000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Desktop"
      >
        <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M342 1954 c-48 -34 -65 -101 -37 -154 32 -61 16 -60 668 -60 385 0
614 -4 652 -11 154 -28 283 -148 324 -299 9 -33 11 -158 9 -455 l-3 -410 -32
-67 c-41 -88 -113 -160 -201 -201 l-67 -32 -420 0 -420 0 -65 31 c-80 38 -133
83 -175 149 -63 99 -65 114 -65 482 l0 333 190 0 189 0 3 -374 3 -374 24 -26
c37 -39 88 -52 133 -33 73 31 73 27 76 439 l3 368 159 0 159 0 3 -368 c3 -412
3 -408 76 -439 45 -19 96 -6 133 33 l24 26 3 444 c2 392 1 449 -13 479 -32 67
-14 65 -702 65 -600 0 -619 -1 -651 -20 -18 -11 -36 -30 -41 -43 -15 -38 -22
-452 -13 -687 9 -214 11 -229 40 -306 75 -203 239 -351 448 -404 71 -18 112
-20 483 -20 380 0 411 1 481 21 229 63 402 238 460 465 18 71 20 112 20 483 0
460 -4 495 -69 626 -84 169 -244 298 -424 340 -51 12 -175 15 -702 15 -564 0
-643 -2 -663 -16z"
            fill={fill}
          />
          <path d="M3105 1491 c-11 -5 -29 -19 -40 -31 -19 -21 -20 -40 -25 -396 l-5
-374 -28 -27 c-24 -24 -35 -28 -87 -28 -52 0 -63 4 -87 28 -25 25 -28 35 -32
112 l-3 85 -101 0 -100 0 6 -106 c7 -122 28 -176 85 -224 57 -47 122 -64 237
-65 149 0 220 28 278 111 41 58 46 122 47 552 l0 372 -62 -1 c-35 0 -72 -4
-83 -8z"
            fill={fill}
          />
          <path d="M4160 980 l0 -520 99 0 99 0 4 248 c3 227 5 251 24 293 31 65 75 94
146 94 70 0 103 -22 138 -91 24 -48 25 -56 29 -296 l3 -248 94 0 94 0 0 293
c0 313 -5 354 -51 421 -31 45 -77 75 -146 93 -105 28 -210 2 -292 -72 l-41
-37 0 135 c0 79 -5 146 -11 160 -16 34 -49 47 -124 47 l-65 0 0 -520z"
            fill={fill}
          />
          <path d="M9264 1481 c-17 -11 -36 -34 -43 -52 -7 -22 -11 -178 -11 -500 l0
-469 100 0 100 0 1 408 1 407 102 -405 102 -405 107 -3 106 -3 8 28 c3 15 50
201 104 413 l97 385 1 -412 1 -413 100 0 100 0 0 520 0 520 -158 0 -157 0 -58
-232 c-80 -322 -139 -549 -143 -546 -1 2 -46 170 -99 373 -53 204 -98 378
-101 388 -5 15 -19 17 -117 17 -92 0 -117 -3 -143 -19z"
            fill={fill}
          />
          <path d="M11120 1045 c0 -486 1 -497 50 -540 19 -17 39 -20 146 -23 l124 -4 0
76 0 76 -38 0 c-21 0 -48 7 -60 16 -22 15 -22 19 -22 250 l0 234 31 0 c47 0
89 47 89 99 l0 41 -60 0 -60 0 0 75 c0 122 -29 155 -140 155 l-60 0 0 -455z"
            fill={fill}
          />
          <path d="M11520 1045 c0 -486 1 -497 50 -540 19 -17 39 -20 146 -23 l124 -4 0
76 0 76 -38 0 c-21 0 -48 7 -60 16 -22 15 -22 19 -22 250 l0 234 31 0 c47 0
89 47 89 99 l0 41 -60 0 -60 0 0 75 c0 122 -29 155 -140 155 l-60 0 0 -455z"
            fill={fill}
          />
          <path d="M3597 1270 c-124 -32 -207 -122 -236 -258 -6 -29 -11 -95 -11 -147 0
-206 57 -322 190 -384 49 -23 68 -26 165 -26 100 0 116 3 173 29 109 50 165
140 184 294 8 66 8 113 0 177 -28 213 -133 314 -337 321 -49 2 -107 -1 -128
-6z m184 -187 c16 -10 39 -38 52 -63 32 -65 32 -233 0 -302 -53 -115 -197
-119 -254 -7 -27 54 -36 172 -19 254 10 47 21 68 50 97 34 34 43 38 90 38 31
0 64 -7 81 -17z"
            fill={fill}
          />
          <path d="M5197 1270 c-89 -23 -142 -65 -176 -140 -32 -70 -33 -70 75 -70 85 0
98 2 113 21 29 36 148 46 203 17 45 -23 41 -84 -8 -107 -13 -6 -68 -17 -121
-26 -130 -21 -182 -39 -230 -80 -51 -43 -75 -102 -75 -180 0 -167 115 -254
317 -242 159 9 262 64 311 164 29 57 29 61 32 258 2 110 0 215 -3 233 -9 50
-67 108 -133 133 -65 24 -240 35 -305 19z m253 -458 c0 -118 -63 -186 -172
-187 -75 0 -103 24 -103 91 0 38 5 50 28 70 15 13 46 28 70 34 23 6 69 19 102
29 33 11 63 19 68 20 4 1 7 -25 7 -57z"
            fill={fill}
          />
          <path d="M5965 1267 c-71 -19 -108 -41 -148 -88 -66 -76 -70 -100 -75 -426
l-4 -293 100 0 100 0 4 283 c3 307 5 319 64 345 40 19 125 15 161 -8 47 -28
53 -65 53 -327 0 -230 0 -232 24 -260 21 -25 31 -28 101 -31 l76 -4 -3 293
c-4 319 -7 338 -63 420 -19 27 -48 49 -91 70 -55 27 -77 32 -157 35 -54 2
-112 -2 -142 -9z"
            fill={fill}
          />
          <path d="M6735 1267 c-71 -19 -108 -41 -148 -88 -66 -76 -70 -100 -75 -426
l-4 -293 100 0 100 0 4 283 c3 307 5 319 64 345 40 19 125 15 161 -8 47 -28
53 -65 53 -327 0 -230 0 -232 24 -260 21 -25 31 -28 101 -31 l76 -4 -3 293
c-4 319 -7 338 -63 420 -19 27 -48 49 -91 70 -55 27 -77 32 -157 35 -54 2
-112 -2 -142 -9z"
            fill={fill}
          />
          <path d="M7525 1267 c-68 -18 -103 -38 -146 -84 -72 -75 -99 -163 -99 -319 0
-234 93 -370 276 -404 180 -34 341 36 399 175 33 79 37 75 -63 75 -87 0 -90
-1 -136 -37 -39 -31 -57 -38 -105 -41 -95 -7 -146 36 -165 141 l-7 37 256 0
c219 0 256 2 261 15 10 26 -15 202 -38 262 -27 73 -96 143 -163 167 -65 23
-205 29 -270 13z m192 -174 c25 -17 63 -89 63 -121 0 -22 -3 -22 -145 -22
-139 0 -145 1 -145 20 0 31 25 85 51 109 44 41 124 47 176 14z"
            fill={fill}
          />
          <path d="M8290 1264 c-145 -39 -222 -156 -190 -286 25 -99 100 -150 285 -193
134 -31 175 -53 175 -93 0 -51 -30 -67 -125 -67 -96 0 -132 18 -151 76 l-12
34 -101 3 -101 3 0 -29 c0 -41 26 -109 55 -148 14 -19 54 -47 92 -66 66 -32
70 -33 198 -32 112 0 138 3 190 23 115 45 173 136 163 255 -10 116 -68 160
-289 219 -154 41 -181 56 -177 100 4 44 36 59 121 55 66 -4 68 -5 111 -51 l43
-47 85 0 85 0 -8 48 c-11 57 -51 117 -104 154 -79 54 -230 73 -345 42z"
            fill={fill}
          />
          <path d="M10547 1270 c-89 -23 -142 -65 -176 -140 -32 -70 -33 -70 75 -70 85
0 98 2 113 21 29 36 148 46 203 17 45 -23 41 -84 -8 -107 -13 -6 -68 -17 -121
-26 -130 -21 -182 -39 -230 -80 -51 -43 -75 -102 -75 -180 0 -167 115 -254
317 -242 159 9 262 64 311 164 29 57 29 61 32 258 2 110 0 215 -3 233 -9 50
-67 108 -133 133 -65 24 -240 35 -305 19z m253 -458 c0 -118 -63 -186 -172
-187 -75 0 -103 24 -103 91 0 38 5 50 28 70 15 13 46 28 70 34 23 6 69 19 102
29 33 11 63 19 68 20 4 1 7 -25 7 -57z"
            fill={fill}
          />
        </g>
      </svg>

      <svg
        height="23"
        viewBox="0 0 560.000000 560.000000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile"
      >
        <g transform="translate(0.000000,560.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M404 5529 c-157 -36 -271 -209 -242 -370 21 -115 77 -193 176 -241
l67 -33 1715 -5 c1598 -6 1720 -7 1790 -24 180 -42 338 -113 460 -205 84 -63
214 -198 269 -280 100 -147 169 -339 191 -529 8 -67 10 -410 8 -1097 -4 -973
-5 -1002 -25 -1090 -43 -186 -105 -320 -214 -464 -181 -241 -457 -400 -764
-441 -66 -8 -360 -11 -1095 -8 l-1005 3 -97 27 c-216 59 -372 145 -518 285
-186 177 -299 390 -344 649 -14 76 -16 218 -16 962 l0 872 535 0 535 0 0 -973
c0 -659 4 -992 11 -1032 28 -150 123 -246 272 -274 127 -23 281 50 339 163 47
91 48 109 48 1144 l0 972 455 0 455 0 2 -1012 c3 -1009 3 -1013 24 -1065 31
-76 75 -125 141 -161 81 -42 149 -53 230 -36 115 24 182 76 230 179 l28 60 0
1230 0 1230 -33 67 c-37 75 -72 110 -152 147 l-55 26 -1745 0 -1745 0 -55 -26
c-93 -44 -155 -125 -180 -234 -14 -62 -14 -2101 0 -2232 60 -565 337 -1034
790 -1338 219 -147 437 -232 735 -288 106 -19 151 -20 1125 -23 592 -1 1050 1
1100 7 477 53 831 224 1150 556 239 248 399 550 465 877 44 222 47 306 42
1381 -4 1095 -3 1063 -62 1296 -125 496 -477 932 -940 1164 -201 100 -402 159
-637 184 -123 14 -3404 13 -3464 0z"
            fill={fill}
          />
        </g>
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
